:root {
  --ck-spacing-standard: 40px;
}

.ck-content {
  font-family: Arial, Helvetica, sans-serif;
  h1, h2, h3, h4, h5, h6 {
    color: #222
  }
  caption, h1, h2, h3 {
    font-weight: 400;
    margin: 0 0 10px;
  }
  a.bold, h4, h5, h6 {
    font-weight: 700;
  }
  .menu-label {
    margin-bottom: 5px;
  }
  h1 {
    font-size: 3.6rem;
    line-height: 4.5rem;
  }
  h2 {
    font-size: 2.4rem;
    line-height: 3.4rem;
  }
  h4, h5, h6, p {
    margin: 10px 0 5px;
  }
  caption, h3 {
    font-size: 2rem;
    line-height: 2.9rem;
  }
  h4, h5, h6 {
    font-size:1.6rem;
  }
}
